import { 
  GET_PROFILE, 
  PROFILE_ERROR, 
  CLEAR_PROFILE,
  GET_USERS,
  GET_USERS_ERROR,
  REMOVE_UESR_BY_ADMIN,
  REMOVE_UESR_BY_ADMIN_ERROR
} from "../actions/types";

const initialState = {
  profile: null,
  profiles: [],
  licenses: [],
  users: [],
  loading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PROFILE:
      return {
        ...state,
        profile: payload,
        loading: false
      };
    case PROFILE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case CLEAR_PROFILE:
      return {
        ...state,
        profile: null,
        licenses: [],
        loading: false
      }
    case GET_USERS:
      return {
        ...state,
        users: payload,
        loading: false
      }
    case GET_USERS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      }
    case REMOVE_UESR_BY_ADMIN:
      return {
        ...state,
        users: state.users.filter(user => user._id !== payload._id),
        loading: false
      };
    case REMOVE_UESR_BY_ADMIN_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    default:
      return state;
  }
}