import { 
  GET_REGCODES,
  GET_REGCODES_ERROR,
  REMOVE_REGCODE,
  REMOVE_REGCODE_ERROR
} from "../actions/types";

const initialState = {
  // profile: null,
  // profiles: [],
  // licenses: [],
  // users: [],
  regcodes: [],
  loading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_REGCODES:
      return {
        ...state,
        regcodes: payload,
        loading: false
      };
    case GET_REGCODES_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case REMOVE_REGCODE:
      return {
        ...state,
        regcodes: state.regcodes.filter(regcode => regcode._id !== payload._id),
        loading: false
      }
    case REMOVE_REGCODE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    default:
      return state;
  }
}