import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Spinner from '../layout/Spinner';
import { getAllRegcodes } from '../../actions/regcode';
import Pagination from '../Pagination';
import RegCodes from '../regcode/RegCodes';

const RegCodeDashboard = ({
  regcode: { regcodes, loading },
  getAllRegcodes
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [codesPerPage] = useState(10);

  // Load all registration codes
  useEffect(() => {
    getAllRegcodes();
  }, [getAllRegcodes]);

  // Get current codes
  const indexOfLastCode = currentPage * codesPerPage;
  const indexOfFirstCode = indexOfLastCode - codesPerPage;
  const currentCodes = regcodes.slice(indexOfFirstCode, indexOfLastCode);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  // console.log(currentCodes);

  return <Fragment>
    { 
      loading ?
        <Spinner />
      :
        <Fragment>
          <div>
            <h1 className="large text-primary">
              Registration Dashboard
            </h1>
            
            <p className="lead">
              <i className="fas fa-id-badge"></i> {' '} List of Registration Codes
            </p>
            <form className="profiles"> 
              { regcodes.length > 0 ?
                (
                  <Fragment>
                    <RegCodes regcodes={currentCodes} />
                    <Pagination 
                      postsPerPage={codesPerPage} 
                      totalPosts={regcodes.length} 
                      currentPage={currentPage}
                      paginate={paginate} />
                  </Fragment>
                )
                :
                <h4>No registration codes found</h4>
              }
              <Link className='btn btn-light my-1' to='/dashboard'>Go Back</Link>
              <Link className='btn btn-primary my-1' to='/create-regcode'>Create Registration Code</Link>
            </form>
          </div>
        </Fragment>
    }
  </Fragment>
};

RegCodeDashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  regcode: PropTypes.object.isRequired,
  getAllRegcodes: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  regcode: state.regcode
});

export default connect(mapStateToProps, { getAllRegcodes })(RegCodeDashboard);