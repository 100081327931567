import React, { useState, useEffect, Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import axios, { CancelToken, isCancel } from "axios";
import $ from "jquery";
import Helpers from "../../utils/helpers";
import { getCurrentProfile } from "../../actions/profile";

const Dashboard = ({
  getCurrentProfile,
  auth: { user, token },
  profile: { profile, loading },
}) => {
  useEffect(() => {
    getCurrentProfile();
  }, [getCurrentProfile]);

  const [downloading, setDownloading] = useState(0);
  const [fetcherrors, setFetcherrors] = useState("");
  const cancelFileDownload = useRef(null);

  const cancelDownload = () => {
    if (cancelFileDownload.current) {
      cancelFileDownload.current("User has canceled the file download.");
      $("#progressbar-container-div").hide();
    }
  };

  return loading && profile === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <h1 className="large text-primary">Dashboard</h1>
      <p className="lead">
        <i className="fas fa-user"></i> Welcome {user && user.name}
      </p>
      {user && user.tier === "tier1" ? (
        <Fragment>
          <Link to="/request-demo" className="btn btn-primary my-1">
            Request Free Demo
          </Link>
        </Fragment>
      ) : (
        ""
      )}
      {profile !== null ? (
        <Fragment>
          <Link to="/view-profile" className="btn btn-primary my-1">
            View My Profile
          </Link>
          {user ? (
            // <Link to="/view-profile" className="btn btn-primary my-1"> TEST </Link>
            <>
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  window.open(
                    "http://www.enomalies.net/auth/hxdr?id=" + user._id,
                    "_self"
                  );
                }}
              >
                Link HxDR Account
              </button>
            </>
          ) : (
            ""
          )}
          {user && user.role !== "gaming" && user.tier !== "tier1" ? (
            <button
              className="btn btn-primary"
              disabled={downloading}
              onClick={(e) => {
                setDownloading(1);
                setFetcherrors(null);

                axios({
                  url: "https://www.enomalies.net/software",
                  // url: 'http://localhost:13804/software',
                  method: "GET",
                  headers: { Authorization: "Bearer " + token },
                  responseType: "blob", // important
                  onDownloadProgress: (progressEvent) => {
                    let percentCompleted = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    if (percentCompleted <= 99) {
                      $("#progressbar-container-div").show();
                      $("#download-progress-bar-div").css(
                        "width",
                        percentCompleted + "%"
                      );
                      $("#download-progress-text").text(
                        "Downloading " + percentCompleted + "%"
                      );
                    } else {
                      $("#progressbar-container-div").hide();
                    }
                  },
                  cancelToken: new CancelToken(
                    (cancel) => (cancelFileDownload.current = cancel)
                  ),
                })
                  .then((response) => {
                    const url = window.URL.createObjectURL(
                      new Blob([response.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "SoftwareDownload.zip");
                    document.body.appendChild(link);
                    link.click();
                    setDownloading(0);
                  })
                  .catch((error) => {
                    console.log("Error", error.message);
                    if (isCancel(error)) {
                      // alert(error.message);
                      console.log("user canceled downloading file.");
                    }
                    setDownloading(0);
                  });
                e.preventDefault();
              }}
            >
              Download Software
            </button>
          ) : (
            ""
          )}
          {user && user.role === "gaming" ? (
            <>
              <button
                className="btn btn-primary"
                disabled={downloading}
                onClick={(e) => {
                  setDownloading(1);
                  setFetcherrors(null);

                  axios({
                    url: "https://www.enomalies.net/software",
                    // url: 'http://localhost:13804/software',
                    method: "GET",
                    headers: { Authorization: "Bearer " + token },
                    params: { softwarename: "MatterpakAttack" },
                    responseType: "blob", // important
                    onDownloadProgress: (progressEvent) => {
                      let percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                      );
                      if (percentCompleted <= 99) {
                        $("#progressbar-container-div").show();
                        $("#download-progress-bar-div").css(
                          "width",
                          percentCompleted + "%"
                        );
                        $("#download-progress-text").text(
                          "Downloading " + percentCompleted + "%"
                        );
                      } else {
                        $("#progressbar-container-div").hide();
                      }
                    },
                    cancelToken: new CancelToken(
                      (cancel) => (cancelFileDownload.current = cancel)
                    ),
                  })
                    .then((response) => {
                      const url = window.URL.createObjectURL(
                        new Blob([response.data])
                      );
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute("download", "SoftwareDownload.zip");
                      document.body.appendChild(link);
                      link.click();
                      setDownloading(0);
                    })
                    .catch((error) => {
                      console.log("Error", error.message);
                      if (isCancel(error)) {
                        // alert(error.message);
                        console.log("user canceled downloading file.");
                      }
                      setDownloading(0);
                    });
                  e.preventDefault();
                }}
              >
                Download Matterpak Attack
              </button>
              <button
                className="btn btn-primary"
                disabled={downloading}
                onClick={(e) => {
                  setDownloading(1);
                  setFetcherrors(null);

                  axios({
                    url: "https://www.enomalies.net/software",
                    // url: 'http://localhost:13804/software',
                    method: "GET",
                    headers: { Authorization: "Bearer " + token },
                    params: { softwarename: "MerryMatterpak" },
                    responseType: "blob", // important
                    onDownloadProgress: (progressEvent) => {
                      let percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                      );
                      if (percentCompleted <= 99) {
                        $("#progressbar-container-div").show();
                        $("#download-progress-bar-div").css(
                          "width",
                          percentCompleted + "%"
                        );
                        $("#download-progress-text").text(
                          "Downloading " + percentCompleted + "%"
                        );
                      } else {
                        $("#progressbar-container-div").hide();
                      }
                    },
                    cancelToken: new CancelToken(
                      (cancel) => (cancelFileDownload.current = cancel)
                    ),
                  })
                    .then((response) => {
                      const url = window.URL.createObjectURL(
                        new Blob([response.data])
                      );
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute("download", "SoftwareDownload.zip");
                      document.body.appendChild(link);
                      link.click();
                      setDownloading(0);
                    })
                    .catch((error) => {
                      console.log("Error", error.message);
                      if (isCancel(error)) {
                        // alert(error.message);
                        console.log("user canceled downloading file.");
                      }
                      setDownloading(0);
                    });
                  e.preventDefault();
                }}
              >
                Download Merry Matterpak
              </button>
            </>
          ) : (
            ""
          )}

          <button
            className="btn btn-primary"
            disabled={downloading}
            onClick={(e) => {
              setDownloading(1);
              setFetcherrors(null);
              Helpers.httpRequest(
                `https://www.enomalies.net/downloadmanual`,
                "get",
                token
              )
                .then((response) => response.blob())
                .then((blob) => {
                  const url = window.URL.createObjectURL(new Blob([blob]));
                  const link = document.createElement("a");
                  link.href = url;

                  link.setAttribute("download", `GettingStartedWithRSET.pdf`);

                  document.body.appendChild(link);
                  link.click();
                  link.parentNode.removeChild(link);
                  setDownloading(0);
                })
                .catch((error) => {
                  console.log(error);
                  error.json().then((json) => {
                    setFetcherrors(json);
                    setDownloading(0);
                  });
                });
              e.preventDefault();
            }}
          >
            Download Manual{" "}
          </button>
        </Fragment>
      ) : (
        <Fragment>
          {/* <p>You have not yet setup a profile, please add some info.</p>
      <Link to='/create-profile' className='btn btn-primary my-1'>
        Create Profile
      </Link> */}
        </Fragment>
      )}
      {user && user.role === "admin" ? (
        <Fragment>
          <Link to="/view-profiles" className="btn btn-primary my-1">
            View Users
          </Link>
          <Link to="/regcode-dashboard" className="btn btn-primary my-1">
            Registration Dashboard
          </Link>
          {/* <button
            className="btn btn-primary"
            onClick={(e) => {
              window.open(
                "https://www.enomalies.net/auth/hxdr?id=" + user._id,
                "_self"
              );
            }}
          >
            Link HxDR Account
          </button> */}
        </Fragment>
      ) : (
        ""
      )}
      {user && user.tier !== "tier4" ? (
        <Fragment>
          <Link to="/purchase-tier" className="btn btn-primary my-1">
            Purchase License
          </Link>
        </Fragment>
      ) : (
        ""
      )}
      <div id="progressbar-container-div" style={{ display: "none" }}>
        <div className="row left-align" id="download-progress-text-div">
          <h6 className="col s12" id="download-progress-text">
            Downloading 0%
          </h6>
        </div>
        <div className="progress">
          <div
            className="determinate"
            styles="width: 1%"
            id="download-progress-bar-div"
          ></div>
        </div>
        <div>
          <button
            className="waves-effect waves-light btn red darken-2 white-text"
            onClick={() => cancelDownload()}
          >
            <i className="material-icons left">clear</i>Cancel
          </button>
        </div>
      </div>
      <div>
        {fetcherrors ? (
          <div className="form-group">
            <div className="alert alert-danger">
              <strong>Error!</strong>{" "}
              {fetcherrors.message || "Something went wrong."}
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

Dashboard.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, { getCurrentProfile })(Dashboard);
