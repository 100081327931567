import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import Moment from 'react-moment';
import moment from 'moment';
import { removeRegCode } from '../../actions/regcode';
import { Link } from 'react-router-dom';

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const RegCodes = ({ 
  regcodes,
  removeRegCode
}) => {
  const regcodeList = regcodes.map(regcode => (
    <tr key={regcode._id}>
      <td>{regcode.code}</td>
      <td>{capitalize(regcode.tier)}</td>
      <td>{capitalize(regcode.role)}</td>
      <td>{regcode.shared ? 'Yes' : 'No'}</td>
      {/* <td>{regcode.used ? 'Yes' : 'No'}</td> */}
      <td>{regcode.shared ? regcode.usecount + '/' + regcode.maxnumregi : (regcode.used ? 1 : 0) }</td>
      <td>{moment(regcode.expdate).format('MM-DD-YYYY')}</td>
      <td>{regcode.notes}</td>
      {/* <td>{user.approved ? 'Yes' : 'No' }</td> */}
      <td>
        <button 
          style={{width: 50}}
          onClick={(e) => {
            e.preventDefault();
            removeRegCode(regcode._id);
          }}
          className='btn btn-danger'
        >
          <i className="fas fa-times"></i>
        </button>
        <Link 
          className='btn btn-light' 
          to={{
            pathname:'/edit-regcode',
            state: {
              regcode: regcode.code,
              tier: regcode.tier,
              role: regcode.role,
              shared: regcode.shared,
              usecount: regcode.usecount,
              maxnumregi: regcode.maxnumregi,
              expdate: regcode.expdate,
              notes: regcode.notes
            }
          }}
          style={{width: 50}}
          >
          <i className="fas fa-user-edit"></i>
        </Link>
      </td>
    </tr>
  ));

  return (
    <Fragment>
      <table className="table">
        <thead>
          <tr>
            <th className="hide-sm">Registration Code</th>
            <th className="hide-sm">Tier</th>
            <th className="hide-sm">Role</th>
            <th className="hide-sm">Shared?</th>
            {/* <th className="hide-sm">Used?</th> */}
            <th className="hide-sm">Use Count</th>
            <th className="hide-sm">Expires At</th>
            <th className="hide-sm">Notes</th>
            {/* <th className="hide-sm">Approved</th> */}
            <th />
          </tr>
        </thead>
        <tbody>
          {regcodeList}
        </tbody>
      </table>
    </Fragment>
  );
}

RegCodes.propTypes = {
  regcodes: PropTypes.array.isRequired,
  removeRegCode: PropTypes.func.isRequired
}

export default connect(null, { removeRegCode })(RegCodes);