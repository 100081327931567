import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const RequestDemo = ({
  auth: { user }  
}) => {
  const [formData] = useState({
    uname: user.name,
    email: user.email,
  });

  const { uname, email } = formData;

const onSubmit = async (e) => {
  e.preventDefault();
    //register({ uname, email, request_notes });
    var mailto="mailto:schmidt@rset.com,info@rset.com?subject=Free%20RSET%20Demo%20Request&body=User%20" + uname + "%20(" + email + ")%20is%20requesting%20a%20free%20RSET%20Demo.";
    window.location.href= mailto;
};

  return (
      <div>
      <h1 className="large text-primary">Request Demo</h1>
      <p className="lead">
        <i className="fas fa-tag"></i> Request a Free 2 week RSET Pro Demo
      </p>
      <form className="form" onSubmit={(e) => onSubmit(e)}>
        <div className="form-group">
        <input type="text" name="name" value={uname} readOnly defaultValue={ user && user.name } />
        </div>
        <div className="form-group">
        <input type="text" name="email" value={email} readOnly defaultValue={ user && user.email } />
        </div>
        <input type="submit" className="btn btn-primary" value="Request Demo" />
        <Link className='btn btn-light my-1' to='/dashboard'>Go Back</Link>
      </form>
      </div>
  );
};

RequestDemo.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth    
});

export default connect(mapStateToProps)(RequestDemo);