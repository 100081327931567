// import React, { Fragment, useState } from 'react';
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import Moment from 'react-moment';
import moment from "moment";
import { removeUserByAdmin } from "../../actions/profile";
import { Link } from "react-router-dom";
// import { removeUserByAdmin } from '../../actions/user';

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const Users = ({ users, removeUserByAdmin }) => {
  const userList = users.map((user) => (
    <tr key={user._id}>
      <td>{user.name}</td>
      <td>{user.email}</td>
      <td>{user.regcode}</td>
      <td>{capitalize(user.tier)}</td>
      <td>{capitalize(user.role)}</td>
      <td>{moment(user.expdate).format("MM-DD-YYYY")}</td>
      <td>{user.notes}</td>
      {/* <td>{user.approved ? 'Yes' : 'No' }</td> */}
      <td>
        <button
          onClick={() => removeUserByAdmin(user._id)}
          className="btn btn-danger"
        >
          <i className="fas fa-times"></i>
        </button>
        <Link
          className="btn btn-light"
          to={{
            pathname: "/edit-user",
            state: {
              email: user.email, // Need user email to target the user
              expdate: user.expdate,
              tier: user.tier, // Pass user's tier and role as well so that admin can change that as well
              role: user.role,
            },
          }}
          style={{ width: 50 }}
        >
          <i className="fas fa-user-edit"></i>
        </Link>
      </td>
    </tr>
  ));

  return (
    <Fragment>
      <table className="table">
        <thead>
          <tr>
            <th className="hide-sm">Name</th>
            <th className="hide-sm">Email</th>
            <th className="hide-sm">Registration Code</th>
            <th className="hide-sm">Tier</th>
            <th className="hide-sm">Role</th>
            <th className="hide-sm">Expires at</th>
            <th className="hide-sm">Notes</th>

            {/* <th className="hide-sm">Approved</th> */}
            <th />
          </tr>
        </thead>
        <tbody>{userList}</tbody>
      </table>
    </Fragment>
  );
};

Users.propTypes = {
  users: PropTypes.array.isRequired,
  removeUserByAdmin: PropTypes.func.isRequired,
};

export default connect(null, { removeUserByAdmin })(Users);
