import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import moment from 'moment';
import Spinner from '../layout/Spinner';
import { Link } from 'react-router-dom';
import { getAllUsers } from '../../actions/profile';
import Pagination from '../Pagination';
import Users from '../user/Users';


const ViewProfiles = ({
  auth: { user },
  profile: { users, loading},
  getAllUsers  
}) => {

  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(7);

  // Load users
  useEffect(() => {
    getAllUsers();
  }, [getAllUsers]);

  // Get current users
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return <Fragment>
    { loading ? 
      <Spinner /> 
      : 
      <Fragment>
        <div>
          <h1 className="large text-primary">
            Users
          </h1>
          <p className="lead">
            <i className="fas fa-user"></i> {' '} All registered users
          </p>
          <div className="profiles">
            { users.length > 0 ? 
              (
                <Fragment>
                  <Users users={currentUsers} />
                  <Pagination 
                      postsPerPage={usersPerPage} 
                      totalPosts={users.length} 
                      currentPage={currentPage}
                      paginate={paginate} />
                </Fragment>
                
              )
              :
              <h4>No users found</h4>
            }
          </div>
          <form className="form"> 
            <Link className='btn btn-light my-1' to='/dashboard'>Go Back</Link>
          </form>
        </div>
      </Fragment> 
    }
  </Fragment>
};

ViewProfiles.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  getAllUsers: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile    
});

export default connect(mapStateToProps, { getAllUsers })(ViewProfiles);