import axios from "axios";
import { setAlert } from "./alert";
import { toast } from "react-toastify";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_PROFILE,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_FAIL,
} from "./types";
import setAuthToken from "../utils/setAuthToken";

// Load User
export const loadUser = () => async (dispatch) => {
  // Is there token? if so, use token in local storage
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get("/auth");

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register User
export const register =
  ({ name, email, password, passcode }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ name, email, password, passcode });

    try {
      const res = await axios.post("/users", body, config);

      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });

      dispatch(loadUser());
    } catch (err) {
      // console.log(err)
      const errors = err.response.data.errors;
      console.log(errors);
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }

      dispatch({
        type: REGISTER_FAIL,
      });
    }
  };

// Login User
export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post("/users/login", body, config);
    // const res = await axios.post('/auth', body, config)

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// Reset password
export const resetPassword = (password, token) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ password });

  try {
    const res = await axios.put(`/resetpassword/${token}`, body, config);

    if (res.status === 200) {
      // Successfully changed password
      toast("Successfully changed password!", {
        type: "success",
        position: "bottom-right",
      });
    } else {
      toast("Error: Failed to reset password. Please contact administrator.", {
        type: "error",
        position: "bottom-right",
      });
    }

    dispatch({
      type: RESET_PASSWORD,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
    });

    toast("Error: Failed to reset password. Please contact administrators.", {
      type: "error",
      position: "bottom-right",
    });
  }

  return;
};

// Forgot password
export const forgotPassword = (email) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email });

  try {
    const res = await axios.post("/forgotpassword", body, config);

    if (res.status === 200) {
      toast("Successfully send out link to reset password!", {
        type: "success",
        position: "bottom-right",
      });
    } else {
      toast("Error: Failed to send email. Please contact administrator.", {
        type: "error",
        position: "bottom-right",
      });
    }

    dispatch({
      type: FORGOT_PASSWORD,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: FORGOT_PASSWORD_FAIL,
    });

    toast("Error: Failed to send email. Please contact administrator.", {
      type: "error",
      position: "bottom-right",
    });
  }
};

// Logout / Clear Profile
export const logout = () => (dispatch) => {
  dispatch({ type: CLEAR_PROFILE });
  dispatch({ type: LOGOUT });
};
