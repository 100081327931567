import { 
  REMOVE_UESR_BY_ADMIN,
  REMOVE_UESR_BY_ADMIN_ERROR
} from "../actions/types";

const initialState = {
  users: [],
  loading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REMOVE_UESR_BY_ADMIN:
      return {
        ...state,
        users: state.users.filter(user => user._id !== payload._id),
        loading: false
      };
    case REMOVE_UESR_BY_ADMIN_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    default:
      return state;
  }
}