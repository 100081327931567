import axios from "axios";
import { setAlert } from "./alert";
import { toast } from "react-toastify";

toast.configure();

// Buy RSET Lite
export const buyRSET_Lite = (token, referralCode) => async (dispatch) => {
  try {
    const product = {
      name: "RSETLite",
      price: 50,
      productBy: "RSET, Inc.",
      referralCode: referralCode,
    };
    const body = { token, product };
    const config = { headers: { "Content-Type": "application/json" } };
    try {
      const paymentResult = await axios.post("/payment", body, config);
      if (paymentResult.status === 200) {
        toast(
          "Success! You successfully purchased an RSET Lite license! Check your email for your new key.",
          {
            type: "success",
            position: "bottom-right",
            onClose: () => window.location.reload(true),
          }
        );
      } else {
        toast("Sorry, something went wrong", {
          type: "error",
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast("Sorry, something went wrong", {
        type: "error",
        position: "bottom-right",
      });
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

// Buy RSET Go
export const buyRSET_Go = (token, referralCode) => async (dispatch) => {
  try {
    const product = {
      name: "RSETGo",
      price: 500,
      productBy: "RSET, Inc.",
      referralCode: referralCode,
    };
    const body = { token, product };
    const config = { headers: { "Content-Type": "application/json" } };
    try {
      const paymentResult = await axios.post("/payment", body, config);
      if (paymentResult.status === 200) {
        toast(
          "Success! You successfully purchased an RSET Go license! Check your email for your new key.",
          {
            type: "success",
            position: "bottom-right",
            onClose: () => window.location.reload(true),
          }
        );
      } else {
        toast("Sorry, something went wrong", {
          type: "error",
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast("Sorry, something went wrong", {
        type: "error",
        position: "bottom-right",
      });
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

// Buy RSET Pro
export const buyRSET_Pro = (token, referralCode) => async (dispatch) => {
  try {
    const product = {
      name: "RSETPro",
      price: 5000,
      productBy: "RSET, Inc.",
      referralCode: referralCode,
    };
    const body = { token, product };
    const config = { headers: { "Content-Type": "application/json" } };
    try {
      const paymentResult = await axios.post("/payment", body, config);
      if (paymentResult.status === 200) {
        toast(
          "Success! You successfully purchased an RSET Pro License! Check your email for your new key.",
          {
            type: "success",
            position: "bottom-right",
            onClose: () => window.location.reload(true),
          }
        );
      } else {
        toast("Sorry, something went wrong", {
          type: "error",
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast("Sorry, something went wrong", {
        type: "error",
        position: "bottom-right",
      });
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

// Buy one key
export const buy1Key = (token, address) => async (dispatch) => {
  try {
    const product = { name: "1key", price: 39, productBy: "Enomalies" };
    const body = { token, product };
    const config = { headers: { "Content-Type": "application/json" } };
    try {
      const paymentResult = await axios.post("/payment", body, config);
      if (paymentResult.status === 200) {
        toast("Success! You successfully purchased 1 key!", {
          type: "success",
          position: "bottom-right",
          onClose: () => window.location.reload(true),
        });
      } else {
        toast("Sorry, something went wrong", {
          type: "error",
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast("Sorry, something went wrong", {
        type: "error",
        position: "bottom-right",
      });
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

// Buy 5 keys
export const buy5Keys = (token, address) => async (dispatch) => {
  try {
    const product = { name: "5keys", price: 90, productBy: "Enomalies" };
    const body = { token, product };
    const config = { headers: { "Content-Type": "application/json" } };
    try {
      const paymentResult = await axios.post("/payment", body, config);
      if (paymentResult.status === 200) {
        toast("Success! You successfully purchased 5 keys!", {
          type: "success",
          position: "bottom-right",
          onClose: () => window.location.reload(true),
        });
      } else {
        toast("Sorry, something went wrong", {
          type: "error",
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast("Sorry, something went wrong", {
        type: "error",
        position: "bottom-right",
      });
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

// Buy 10 keys
export const buy10Keys = (token, address) => async (dispatch) => {
  try {
    const product = { name: "10keys", price: 170, productBy: "Enomalies" };
    const body = { token, product };
    const config = { headers: { "Content-Type": "application/json" } };
    try {
      const paymentResult = await axios.post("/payment", body, config);
      if (paymentResult.status === 200) {
        toast("Success! You successfully purchased 10 keys!", {
          type: "success",
          position: "bottom-right",
          onClose: () => window.location.reload(true),
        });
      } else {
        toast("Sorry, something went wrong", {
          type: "error",
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast("Sorry, something went wrong", {
        type: "error",
        position: "bottom-right",
      });
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

// Make payment
export const makePayment = (token, address) => async (dispatch) => {
  console.log(token);
  try {
    const product = {
      name: "Tier 1",
      price: 100,
      productBy: "Enomalies",
    };

    const body = {
      token,
      product,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const paymentResult = await axios.post("/payment", body, config);

      console.log(paymentResult);

      // Dispatch
    } catch (error) {
      console.log("ERROR");
    }
  } catch (err) {
    const errors = err.response.data.errors;

    // Show all errors returned from backend server
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    // It seems like dispatching any action is not needed for this
  }
};
