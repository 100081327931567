import React from 'react'
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export const Landing = ({ isAuthenticated }) => {
  if (isAuthenticated) {
    return <Redirect to='/dashboard' />
  }
  return (
  <section className="landing">
    <div className="dark-overlay">
      <div className="landing-inner">
        {/* <h1 className="x-large">ENOMALIES</h1>
        <p className="lead">
          RSET is a first-of-its-kind virtual environment creation tool. Explore, train, and interact with your environment like never before.
        </p> */}

      
        <div className="row valign-wrapper">
          <div className="col s8 offset-s2">
          <img src="/enomalies-logo.png" alt="enomalieslogo" className="responsive-img"></img>
          </div>
        </div>
        <div className="row valign-wrapper logo p-2">
          <div className="col s12 m6">
            <img src="/rset_logo.png" alt="rset" className="responsive-img"></img>
          </div>  

          <div className="col s12 m6">
            <img src="/freestyle_logo.png" alt="freestyle" className="responsive-img"></img>
          </div>  
        </div>
        &nbsp;
        <div className="buttons">
          <Link to="/register" className="btn btn-large btn-primary">Sign Up</Link>
          <Link to="/login" className="btn btn-large btn-light">Login</Link>
        </div>

        
      </div>
     
    </div>
  </section>
  )
}

Landing.propTypes = {
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(Landing);