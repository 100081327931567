import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Helpers from '../../utils/helpers';
import axios, {CancelToken, isCancel} from 'axios';
import $ from 'jquery';

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const ViewProfile = ({
  auth: { user, token }  
}) => {
  const [downloading, setDownloading] = useState(0);
  const [fetcherrors, setFetcherrors] = useState('');
  const cancelFileDownload = useRef(null);

  const cancelDownload = () => {
    if(cancelFileDownload.current)
    {
      cancelFileDownload.current("User has canceled the file download.");
      $('#progressbar-container-div').hide();
    }
  };

  return (
    <div>
      <h1 className="large text-primary">
        Your Profile
      </h1>
      <p className="lead">
        <i className="fas fa-user"></i> {' '} Your current profile
      </p>
      <form className="form"> 
        <div className="form-group">
          <h5>Name</h5>
            <input type="text" name="name" readOnly defaultValue={ user && user.name } />
        </div>
        <div className="form-group">
          <h5>Tier</h5>
            <input type="text" name="tier" readOnly defaultValue={ user && capitalize(user.tier) } />
        </div>
        <div className="form-group">
          <h5>Role</h5>
            <input type="text" name="role" readOnly defaultValue={ user && capitalize(user.role) } />
        </div>
        <div className="form-group">
          <h5>Registration Code</h5>
            <input type="text" name="regcode" readOnly defaultValue={ user && user.regcode } />
        </div>
        <div className="form-group">
          <h5>Purchased Keys</h5>
            <input type="text" name="keys" readOnly defaultValue={ user && user.key } />
        </div>
        <div className="form-group">
          <h5>Regcode Expiration Date</h5>
            <input 
              type="text"
              name="expdate" 
              readOnly 
              defaultValue={ user && moment(user.expdate).format('YYYY-MM-DD h:mm:ss a') } 
            />
          { 
            user && moment(user.expdate) < moment() ?
            <small className="form-text-error">Your regcode has expired</small> 
            :
            <small className="form-text-ok">Your regcode is valid</small> 
          }
        </div>
        {/* <button className="btn btn-light">
          <a href="https://s3.us-east-2.amazonaws.com/files.rset.com/rset-releases/RSET+v0.1.0.zip" download>Download RSET Software</a>
        </button> */}
        {user.tier !== "tier1" ? (
          <button className='btn btn-light' disabled={downloading} onClick={(e) => { 
          setDownloading(1);
          setFetcherrors(null);

          axios({
            url: 'https://www.enomalies.net/software',
            // url: 'http://localhost:13804/software',
            method: 'GET',
            headers: {'Authorization': 'Bearer '+token},
            responseType: 'blob', // important
            onDownloadProgress: (progressEvent) => {
              let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              if (percentCompleted <= 99) {
                $('#progressbar-container-div').show();
                $('#download-progress-bar-div').css('width', percentCompleted + '%');
                $('#download-progress-text').text('Downloading ' + percentCompleted + '%');
              } else {
                $('#progressbar-container-div').hide();
              }
            },
            cancelToken: new CancelToken( cancel => cancelFileDownload.current = cancel )
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'SoftwareDownload.zip');
            document.body.appendChild(link);
            link.click();
            setDownloading(0);
          }).catch((error) => {
            console.log('Error', error.message);
            if(isCancel(error)) {
              // alert(error.message);
              console.log("user canceled downloading file.");
            }
            setDownloading(0);
          });
          e.preventDefault();   
        }}>Download Software</button>
          ) : (
            ""
          )}
        <button className='btn btn-light' disabled={downloading} onClick={(e) => {
          setDownloading(1);
          setFetcherrors(null);
          Helpers.httpRequest(
            `https://www.enomalies.net/downloadmanual`,
            'get',
            token,
          )
          .then((response) => response.blob())
          .then((blob) => {

            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', `GettingStartedWithRSET.pdf`);

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            setDownloading(0);
          }).catch((error) => {
            console.log(error);
            error.json().then((json) => {
              setFetcherrors(json);
              setDownloading(0);  
            })
            
          })
          e.preventDefault();
        }}>Download Software Manual (.pdf)</button>
        <Link className='btn btn-light my-1' to='/dashboard'>Go Back</Link>
      </form>
      <div id='progressbar-container-div' style={{display: "none"}}> 
        <div className="row left-align" id='download-progress-text-div'>
          <h6 className="col s12" id='download-progress-text'>Downloading 0%</h6>
        </div>
        <div className="progress">
          <div className="determinate" styles="width: 1%" id='download-progress-bar-div'></div>
        </div>
        <div>
        <button className="waves-effect waves-light btn red darken-2 white-text" onClick={() => cancelDownload()}><i className="material-icons left">clear</i>Cancel</button>
        </div>
      </div>
      <div>

      {(fetcherrors)
        ? (<div className="form-group">
            <div className="alert alert-danger"><strong>Error!</strong> {fetcherrors.message || 'Something went wrong.'}</div>
          </div>
        )
        : null
      }
      </div>
    </div>
  );
};

ViewProfile.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth    
});

export default connect(mapStateToProps)(ViewProfile);