import React from "react";
import { Route, Switch } from "react-router-dom";
import Register from "../auth/Register";
import Login from "../auth/Login";
import ForgotPassword from "../auth/ForgotPassword";
import ResetPassword from "../auth/ResetPassword";
import Alert from "../layout/Alert";
import Dashboard from "../dashboard/Dashboard";
import ViewProfile from "../profile-forms/ViewProfile";
import ViewProfiles from "../profile-forms/ViewProfiles";
import RegCodeDashboard from "../regcode/RegCodeDashboard";
import CreateRegCode from "../regcode/CreateRegCode";
import PurchaseTier from "../payment/PurchaseTier";
import PurchaseKey from "../payment/PurchaseKey";
import PrivateRoute from "../routing/PrivateRoute";
import AdminRoute from "../routing/AdminRoute";
import NotFound from "../layout/NotFound";
import EditRegCode from "../regcode/EditRegCode";
import EditUser from "../user/EditUser";
import RequestDemo from "../demo/RequestDemo";

const Routes = () => {
  return (
    <section className="container">
      <Alert />
      <Switch>
        <Route exact path="/register" component={Register} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgotpassword" component={ForgotPassword} />
        <Route exact path="/resetpassword" component={ResetPassword} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/view-profile" component={ViewProfile} />
        {/* <PrivateRoute exact path="/purchase-tier" component={PurchaseTier} /> */}
        <PrivateRoute exact path="/purchase-tier" component={PurchaseTier} />
        <PrivateRoute exact path="/purchase-key" component={PurchaseKey} />
        <PrivateRoute exact path="/request-demo" component={RequestDemo} />
        <AdminRoute exact path="/view-profiles" component={ViewProfiles} />
        <AdminRoute
          exact
          path="/regcode-dashboard"
          component={RegCodeDashboard}
        />
        <AdminRoute exact path="/create-regcode" component={CreateRegCode} />
        <AdminRoute exact path="/edit-regcode" component={EditRegCode} />
        <AdminRoute exact path="/edit-user" component={EditUser} />
        <Route component={NotFound} />
      </Switch>
    </section>
  );
};

export default Routes;
