import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const AdminRoute = ({ component: Component, auth: { isAuthenticated, loading, user }, ...rest }) => (
  <Route 
    {...rest} 
    render={props => 
      !isAuthenticated && !loading ? (
        <Redirect to='/login' />
      ) : (
        (user && user.role === 'admin') ? (
          <Component {...props} />
        ):
        (
          <Redirect to='/dashboard' />
        )
      )
    } />
);

AdminRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(AdminRoute);
