import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";

export const Navbar = ({
  auth: { isAuthenticated, loading, user },
  logout,
}) => {
  const authLinks = (
    <ul id="nav-mobile" className="right hide-on-med-and-down">
      <li>
        <Link to="/dashboard">
          {/* <i className="fas fa-user"></i>{' '} */}
          <span className="hide-sm">Dashboard</span>
        </Link>
      </li>
      <li>
        <a onClick={logout} href="#/">
          {/* <i className="fas fa-sign-out-alt"></i>{' '} */}
          <span className="hide-sm">Logout</span>
        </a>
      </li>
    </ul>
  );

  // const adminLinks= (
  //   <ul id="nav-mobile" className="right hide-on-med-and-down">
  //     <li>
  //       <Link to="/dashboard">
  //       <i className="fas fa-user"></i>{' '}
  //       <span className="hide-sm">Admin Dashboard</span></Link></li>
  //     <li><a onClick={logout} href="#!">
  //     <i className="fas fa-sign-out-alt"></i>{' '}
  //     <span className="hide-sm">Logout</span></a>
  //     </li>
  //   </ul>
  // );

  const guestLinks = (
    <ul id="nav-mobile" className="right hide-on-med-and-down">
      <li>
        <Link to="/register">Register</Link>
      </li>
      <li>
        <Link to="/login">Login</Link>
      </li>
    </ul>
  );

  return (
    <nav>
      <div className="nav-wrapper">
        <div className="brand-logo">
          <Link to="/">
            <img src="/favicon.ico" alt="fav icon"></img> Enomalies
          </Link>
        </div>

        {
          // !loading && (<Fragment>{ isAuthenticated ? (user.role !== "viewer" ? adminLinks : authLinks) : guestLinks }</Fragment>)
          !loading && (
            <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>
          )
        }
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Navbar);
