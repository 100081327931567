import axios from "axios";
// import { setAlert } from './alert';

import {
  GET_PROFILE,
  PROFILE_ERROR,
  GET_USERS,
  GET_USERS_ERROR,
  REMOVE_UESR_BY_ADMIN,
  REMOVE_UESR_BY_ADMIN_ERROR,
} from "./types";
// import { setAlert } from './alert';
import { toast } from "react-toastify";

// Get current users profile
export const getCurrentProfile = () => async (dispatch) => {
  try {
    const res = await axios.get("/users/me");

    dispatch({
      type: GET_PROFILE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.resonse.statusText, status: err.response.status },
    });
  }
};

// Get all users
export const getAllUsers = () => async (dispatch) => {
  try {
    const res = await axios.get("/users");

    dispatch({
      type: GET_USERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_USERS_ERROR,
      payload: { msg: err.resonse.statusText, status: err.response.status },
    });
  }
};

// Delete user by admin user
export const removeUserByAdmin = (id) => async (dispatch) => {
  if (window.confirm("Are you sure? This can NOT be undone!")) {
    try {
      const res = await axios.delete(`/users/id/${id}`);

      dispatch({
        type: REMOVE_UESR_BY_ADMIN,
        payload: res.data,
      });

      // dispatch(setAlert('User removed', 'success'));
      toast("Successfully removed a user!", {
        type: "success",
        position: "bottom-right",
      });
    } catch (err) {
      toast("Error: " + err, {
        type: "error",
        position: "bottom-right",
      });
      dispatch({
        type: REMOVE_UESR_BY_ADMIN_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  }
};
