import axios from 'axios';
import { setAlert } from './alert';
import {
  GET_REGCODES,
  GET_REGCODES_ERROR,
  REMOVE_REGCODE,
  REMOVE_REGCODE_ERROR
} from './types';
import { toast } from 'react-toastify';


// Edit registration code by admin user
export const editRegCode = (formData, history) => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    await axios.patch('/regcode', formData, config);

    // It seems like dispatching any action is not needed for this

    // Show alert for successful registration code generation
    // dispatch(setAlert('Registration Code Added', 'success'));
    toast('Successfully updated registration code!', {
      type: 'success',
      position: 'bottom-right'
    })
    
  } catch (err) {
    const errors = err.response.data.errors;

    // Show all errors returned from backend server
    if (errors) {
      errors.forEach(error => {
        toast('Error: ' + error.msg, {
          type: 'error',
          position: 'bottom-right'
        })
        // dispatch(setAlert(error.msg, 'danger'))
      });
    }

    // It seems like dispatching any action is not needed for this
  }
}

// Add registration code
export const addRegCode = (formData, history) => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    await axios.post('/regcode', formData, config);

    // It seems like dispatching any action is not needed for this

    // Show alert for successful registration code generation
    // dispatch(setAlert('Registration Code Added', 'success'));
    toast('Successfully created registration code!', {
      type: 'success',
      position: 'bottom-right'
    })
    
  } catch (err) {
    const errors = err.response.data.errors;

    // Show all errors returned from backend server
    if (errors) {
      errors.forEach(error => {
        toast('Error: ' + error.msg, {
          type: 'error',
          position: 'bottom-right'
        })
        // dispatch(setAlert(error.msg, 'danger'))
      });
    }

    // It seems like dispatching any action is not needed for this
  }
}

// Show registration error
export const showRegAlert = (alertMessage, alertType) => async dispatch => {
  // Dispatch alert
  dispatch(setAlert(alertMessage, alertType));
}

// Get all registration codes 
export const getAllRegcodes = () => async dispatch => {
  try {
    const res = await axios.get('/regcodes');

    dispatch({
      type: GET_REGCODES,
      payload: res.data
    })
  } catch (err) {
    dispatch({
      type: GET_REGCODES_ERROR,
      payload: { msg: err.resonse.statusText, status: err.response.status }
    })
  }
}

// Delete registration code by admin user
export const removeRegCode = id => async dispatch => {
  if(window.confirm('Are you sure? This can NOT be undone!')) {   
    try {
      const res = await axios.delete(`/regcode/${id}`);

      // console.log('Returned data from backend:', res);
      dispatch({
        type: REMOVE_REGCODE,
        payload: res.data
      })

      // dispatch(setAlert('Registration code removed', 'success'));
      toast('Success! Registration code removed', {
        type: 'success',
        position: 'bottom-right'
      });
    } catch (err) {
      dispatch({
        type: REMOVE_REGCODE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      })
      
    }
  }
}