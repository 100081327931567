import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { Link, withRouter } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { addRegCode, showRegAlert } from "../../actions/regcode";

const initialState = {
  code: "",
  notes: "",
  role: "role1",
  tier: "tier1",
  expdate: moment().add(30, "days").format("YYYY-MM-DD"),
  expafter: 0,
  maxnumregi: 1,
  shared: false,
};

const CreateRegCode = ({ auth: { user }, addRegCode, showRegAlert }) => {
  const [formData, setFormData] = useState(initialState);

  const { code, notes, role, tier, expdate, expafter, maxnumregi, shared } =
    formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onClickGenerate = (e) => {
    e.preventDefault();
    const randomId = uuidv4();

    // Put created randon ID and then simulate onChange event through React (tricky)
    let codeInputElement = document.getElementById("regcode_input_text");
    let lastValue = codeInputElement.value;

    codeInputElement.value = randomId;
    var event = new Event("input", { bubbles: true });
    event.simulated = true;

    let tracker = codeInputElement._valueTracker;
    if (tracker) {
      tracker.setValue(lastValue);
    }
    codeInputElement.dispatchEvent(event);
  };

  return (
    <div>
      <h1 className="large text-primary">Create Registration Code</h1>
      <p className="lead">
        <i className="fas fa-id-badge"></i> Create New Registration Code
      </p>
      <small>* = required field</small>
      <div className="row">
        <form
          className="col s12"
          onSubmit={(e) => {
            e.preventDefault();
            // Check if expdate is valid
            if (isNaN(Date.parse(formData.expdate)) === false) {
              // Adjust timezone to EST for now
              const adjustFormData = {
                ...formData,
                expdate: new Date(formData.expdate).toISOString(),
              };
              addRegCode(adjustFormData);
            } else {
              showRegAlert("Invalid date", "danger");
            }
          }}
        >
          <div className="row col s12">
            <div className="input-field col s10">
              <input
                type="text"
                className="validate"
                id="regcode_input_text"
                name="code"
                value={code}
                placeholder="Registration Code(*)"
                minLength="6"
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className="input-field col s2">
              <button
                className="btn waves-effect waves-light"
                onClick={onClickGenerate}
              >
                Generate
              </button>
            </div>
          </div>

          <div className="row col s12">
            <label>Tier(*)</label>
            <select
              className="browser-default"
              name="tier"
              value={tier}
              onChange={(e) => onChange(e)}
            >
              <option disabled>* Select tier to be assigned</option>
              <option value="tier1">
                Tier 1: single/bundle scan purchases, no subscription
              </option>
              <option value="tier2">
                Tier 2: unlimited scans, subscription, basic features
              </option>
              <option value="tier3">
                Tier 3: unlimited scans, subscription, all features
              </option>
              <option value="tier4">
                Tier 4: power users, special financing, all features incl' beta
                features
              </option>
            </select>
          </div>

          <div className="row col s12">
            <label>Role(*)</label>
            <select
              className="browser-default"
              name="role"
              value={role}
              onChange={(e) => onChange(e)}
            >
              <option disabled>* Select role to be assigned</option>
              <option value="role1">Role 1: Defense</option>
              <option value="role2">Role 2: Entertainment</option>
              <option value="role3">Role 3: Education</option>
              <option value="role4">Role 4: Construction</option>
              <option value="role5">Role 5: Gaming</option>
            </select>
          </div>

          <div className="row col s12">
            <label>Sharing(*)</label>
            <select
              className="browser-default"
              name="shared"
              value={shared}
              onChange={(e) => onChange(e)}
            >
              <option disabled>* Select Enabled or Disabled</option>
              <option value="true">Enabled</option>
              <option value="false">Disabled</option>
            </select>
          </div>

          <div className="row col s12">
            <label>Notes</label>
            <textarea
              id="regcode_notes"
              name="notes"
              value={notes}
              placeholder="Notes for the registration code"
              onChange={(e) => onChange(e)}
            ></textarea>
          </div>

          {
            /* If the sharing is enabled, then show maximum counts for share */
            formData.shared === "true" ? (
              <div className="row col s12">
                <label>Maximum number of shared registration</label>
                <input
                  type="number"
                  name="maxnumregi"
                  min="0"
                  step="1"
                  value={maxnumregi}
                  onChange={(e) => onChange(e)}
                ></input>
              </div>
            ) : null
          }

          <div className="row col s12">
            <label>Expiration Date(*)</label>
            <input
              type="date"
              name="expdate"
              value={expdate}
              onChange={(e) => onChange(e)}
              required
            ></input>
            <small className="form-text-warning">Expiration date is EST</small>
          </div>

          <div className="row col s12">
            <label>Expire After Registration (in days)</label>
            <input
              type="number"
              name="expafter"
              min="0"
              step="1"
              value={expafter}
              onChange={(e) => onChange(e)}
              required
            ></input>
          </div>
          <input type="submit" className="btn btn-primary my-1" />
          <Link className="btn btn-light my-1" to="/regcode-dashboard">
            Go Back
          </Link>
        </form>
      </div>
    </div>
  );
};

CreateRegCode.propTypes = {
  auth: PropTypes.object.isRequired,
  addRegCode: PropTypes.func.isRequired,
  showRegAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { addRegCode, showRegAlert })(
  withRouter(CreateRegCode)
);
