import { 
  MAKE_PAYMENT
} from "../actions/types";

const initialState = {
  loading: true,
  error: {}
};

export default function(state = initialState, action) {
  // const { type, payload } = action;
  const { type } = action;

  switch (type) {
    case MAKE_PAYMENT:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}