import axios from 'axios';
import { setAlert } from './alert';
import { toast } from 'react-toastify';

// import { 
//   REMOVE_UESR_BY_ADMIN, 
//   REMOVE_UESR_BY_ADMIN_ERROR 
// } from './types';

// Edit user data by admin user
export const editUser = (formData, history) => async dispatch => {

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    await axios.patch('/users', formData, config);

    // It seems like dispatching any action is not needed for this

    // Show alert for successful registration code generation
    // dispatch(setAlert('Registration Code Added', 'success'));
    toast('Successfully updated user data!', {
      type: 'success',
      position: 'bottom-right'
    })
    
  } catch (err) {
    const errors = err.response.data.errors;

    // Show all errors returned from backend server
    if (errors) {
      errors.forEach(error => {
        toast('Error: ' + error.msg, {
          type: 'error',
          position: 'bottom-right'
        })
        // dispatch(setAlert(error.msg, 'danger'))
      });
    }

    // It seems like dispatching any action is not needed for this
  }
}

// Show registration error
export const showRegAlert = (alertMessage, alertType) => async dispatch => {
  // Dispatch alert
  dispatch(setAlert(alertMessage, alertType));
}


// // Delete user by user id (required admin)
// export const removeUserByAdmin = id => async dispatch => {
//   console.log("removeUserByAdmin called");
//   if(window.confirm('Are you sure? This can NOT be undone!')) {   
//     try {
//       const res = await axios.delete(`/users/id/${id}`);

//       console.log('Returned data from backend:', res);
//       dispatch({
//         type: REMOVE_UESR_BY_ADMIN,
//         payload: res.data
//       })

//       dispatch(setAlert('User removed', 'success'));
//     } catch (err) {
//       dispatch({
//         type: REMOVE_UESR_BY_ADMIN_ERROR,
//         payload: { msg: err.response.statusText, status: err.response.status }
//       })
      
//     }
//   }
// }