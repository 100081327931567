import React from 'react';

const Pagination = ({ postsPerPage, totalPosts, currentPage, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
      <ul className="pagination">
        <li className = {currentPage === 1 ? "disabled": "waves-effect"}><a href={window.location.href} onClick={() => 
          currentPage === 1 ? console.log("First page") : paginate(currentPage-1) 
        } ><i className = "material-icons">chevron_left</i></a></li>
        { pageNumbers.map(number => (
          <li key={number} className={currentPage === number ? "active" : "waves-effect"}>
            <a href={window.location.href} onClick={() => paginate(number)}>
              {number}
            </a>
          </li>
        ))}
       <li className = {currentPage === pageNumbers.length ? "disabled" : "waves-effect"}><a href={window.location.href} onClick={() => 
        currentPage === pageNumbers.length ? console.log("Last page") : paginate(currentPage+1)
      }><i className = "material-icons">chevron_right</i></a></li>
      </ul>

  );
};

export default Pagination;