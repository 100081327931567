// import React, { useState } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import { v4 as uuidv4 } from 'uuid';
import { 
  makePayment, 
  buy1Key,
  buy5Keys,
  buy10Keys
 } from '../../actions/payment';
import StripeCheckout from 'react-stripe-checkout';

const PurchaseKey = ({ 
  auth: { user },
  makePayment,
  buy1Key,
  buy5Keys,
  buy10Keys
}) => {

  const product_1_key = {
    name: "Buy 1 Key",
    price: 39,
    perkeyprice: 39,
    productBy: "Enomalies"
  }

  // const product_5_key = {
  //   name: "Buy 5 Keys",
  //   price: 150,
  //   perkeyprice: 30,
  //   productBy: "Enomalies"
  // }
  
  return (
    <div>
      <h1 className="large text-primary">
        Purchase Keys
      </h1>
      <blockquote>You can buy keys to load your own 3D environment files into our software.</blockquote>
      <div className="row">
        <div className="col s12 m12">
          <div className="card small">
            <div className="card-content">
              <span className="card-title">Buy 1 Key<span className="new badge red" data-badge-caption="KEY">1</span></span>
              <p>Each key can be used to load your own 3D modeling environment into our software.
              The price of each key is ${product_1_key.perkeyprice}.</p>            
            </div>
            <div className="card-action center">
              <StripeCheckout 
                image="https://www.enomalies.net/enom-logo-center.png"
                stripeKey="pk_live_TBYf1ZxD4rUfFD9WOIFVlJP7007VlV7M1k"  
                // stripeKey="pk_test_GFJ4kw9ZBG1QhwYGPNli9DWi00TAnpgCLu"  
                token={buy1Key} 
                name={product_1_key.name}
                amount={product_1_key.price * 100}
                billingAddress
              >  
                <button className='btn btn-primary my-1'>Buy 1 Key (${product_1_key.price})</button>
              </StripeCheckout>
            </div>
          </div>
        </div>
        {/* <div className="col s12 m6">
          <div className="card small">
            <div className="card-content">
              <span className="card-title">Buy 5 Keys<span className="new badge red" data-badge-caption="KEYS">5</span></span>
              <p>Each key can be used to load your own 3D modeling environment into our software.
              The price of each key is ${product_5_key.perkeyprice}.</p>
            </div>
            <div className="card-action center">
              <StripeCheckout 
                image="https://www.enomalies.net/enom-logo-center.png"
                stripeKey="pk_live_TBYf1ZxD4rUfFD9WOIFVlJP7007VlV7M1k"  
                // stripeKey="pk_test_GFJ4kw9ZBG1QhwYGPNli9DWi00TAnpgCLu"  
                token={buy5Keys} 
                name={product_5_key.name}
                amount={product_5_key.price * 100}
                billingAddress
              >
                <button className='btn btn-primary my-1'>Buy 5 Keys (${product_5_key.price})</button>
              </StripeCheckout>
            </div>
          </div>
        </div> */}
      </div>
 
      <div className="row">
        <div className="col s12">
          <div className="card small">
            <div className="card-content">
              <span className="card-title">Upgrade Your Subscription for Unlimited Keys<span className="new badge red" data-badge-caption="KEYS">UNLIMITED</span></span>
              <p>You can upgrade your subscription and you will get unlimited keys.</p>
              
            </div>
            <div className="card-action center">
              <Link to='/purchase-tier' className='btn btn-primary waves-effect btn-large my-1'>
                Manage your subscription
              </Link>
            </div>
          </div>
        </div>
      </div>
      
      <Link className='btn btn-light my-1' to='/dashboard'>Go Back</Link>
    </div>
  );
};

PurchaseKey.propTypes = {
  makePayment: PropTypes.func.isRequired,
  buy1Key: PropTypes.func.isRequired,
  buy5Keys: PropTypes.func.isRequired,
  buy10Keys: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth    
});

export default connect(mapStateToProps, { 
  makePayment, 
  buy1Key,
  buy5Keys,
  buy10Keys
})(PurchaseKey);