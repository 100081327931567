import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import { resetPassword } from "../../actions/auth";
import PropTypes from "prop-types";

const ResetPassword = ({
  setAlert,
  resetPassword,
  isAuthenticated,
  redirectToLogin,
}) => {
  const [formData, setFormData] = useState({
    password: "",
  });

  const { password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    // Check if the password is empty
    if (password.length === 0) {
      setAlert("Please enter valid email", "danger");
    }

    // Get token from url
    const queryString = window.location.hash.substr(1).split("/")[2];
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get("token");

    // Reset password (with reset token)
    resetPassword(password, token);
  };

  if (isAuthenticated || redirectToLogin) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Fragment>
      <h1 className="large text-primary">Reset Password</h1>
      <p className="lead">Enter new passwords</p>
      <form className="form" onSubmit={(e) => onSubmit(e)}>
        <div className="form-group">
          <input
            type="password"
            placeholder="Password"
            name="password"
            value={password}
            onChange={(e) => onChange(e)}
            required
            minLength="7"
          />
        </div>
        <input type="submit" className="btn btn-primary" value="Submit" />
      </form>
    </Fragment>
  );
};

ResetPassword.propTypes = {
  setAlert: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  redirectToLogin: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  redirectToLogin: state.auth.redirectToLogin,
});

export default connect(mapStateToProps, { setAlert, resetPassword })(
  ResetPassword
);
