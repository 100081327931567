// import React, { useState } from 'react';
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import { v4 as uuidv4 } from 'uuid';
import { buyRSET_Lite, buyRSET_Go, buyRSET_Pro } from "../../actions/payment";
import StripeCheckout from "react-stripe-checkout";

const PurchaseTier = ({
  auth: { user },
  buyRSET_Lite,
  buyRSET_Go,
  buyRSET_Pro,
}) => {
  const [referralCode, setReferralCode] = useState("");

  const onReferralCodeChangeHandler = (event) => {
    setReferralCode(event.target.value);
  };

  const handleLitePaymentSubmit = (event) => {
    buyRSET_Lite(event, referralCode);
  };

  const handleGoPaymentSubmit = (event) => {
    buyRSET_Go(event, referralCode);
  };

  const handleProPaymentSubmit = (event) => {
    buyRSET_Pro(event, referralCode);
  };

  const RSET_LITE = {
    name: "Buy RSET Lite",
    price: 50,
    productBy: "RSET, Inc.",
  };

  const RSET_GO = {
    name: "Buy RSET Go",
    price: 500,
    productBy: "RSET, Inc.",
  };

  // const RSET_PRO = {
  //   name: "Buy RSET Pro",
  //   price: 5000,
  //   productBy: "RSET, Inc.",
  // };

  return (
    <div>
      <h1 className="large text-primary">Purchase License</h1>
      <blockquote>Choose the license that best fits your use case.</blockquote>
      <blockquote>Want to try RSET before purchasing a license? <a href="https://rset.com/rset-free">Give RSET Free a try!</a></blockquote>
      <div className="row">
        <div className="col m4">
          <div className="card medium">
            <div className="card-content">
              <span className="card-title">
                RSET Lite
                <span
                  className="new badge blue"
                  data-badge-caption="Perpetual with 1 Year of Updates"
                ></span>
              </span>
              <ul>
                <li style={{color: "green"}}>
                  <i className="fas fa-check text-success"></i> Explore a single environment (purchase additional licenses to add more)
                </li>
                <li style={{color: "green"}}>
                  <i className="fas fa-check text-success"></i> Walking Exploration
                </li>
                <li style={{color: "green"}}>
                  <i className="fas fa-check text-success"></i> Advanced Exploration Tools
                </li>
                <li style={{color: "red"}}>
                  <i className="fas fa-times text-danger"></i> Load Scenarios
                </li>
                <li style={{color: "red"}}>
                  <i className="fas fa-times text-danger"></i> Randomized Training
                </li>
                <li style={{color: "red"}}>
                  <i className="fas fa-times text-danger"></i> Create/Share Your Own Scenarios
                </li>
                <li style={{color: "red"}}>
                  <i className="fas fa-times text-danger"></i> Networked Exploration and Training
                </li>
              </ul>
            </div>
            <div className="card-action center">
              <input
                placeholder="Referral Code"
                style={{ width: "200px", margin: "0px" }}
                type="text"
                name="name"
                onChange={onReferralCodeChangeHandler}
                value={referralCode}
              />

              <StripeCheckout
                image="https://www.enomalies.net/enom-logo-center.png"
                stripeKey="pk_live_TBYf1ZxD4rUfFD9WOIFVlJP7007VlV7M1k"
                //stripeKey="pk_test_GFJ4kw9ZBG1QhwYGPNli9DWi00TAnpgCLu"
                token={handleLitePaymentSubmit}
                name={RSET_LITE.name}
                amount={RSET_LITE.price * 100}
                billingAddress
              >
                <button className="btn btn-primary my-1">
                  Buy RSET Lite (${RSET_LITE.price})
                </button>
              </StripeCheckout>
            </div>
          </div>
        </div>
        <div className="col m4">
          <div className="card medium">
            <div className="card-content">
              <span className="card-title">
                RSET Go
                <span
                  className="new badge blue"
                  data-badge-caption="Perpetual with 1 Year of Updates"
                ></span>
              </span>
              <ul>
              <li style={{color: "green"}}>
                  <i className="fas fa-check text-success"></i> Explore unlimited environments
                </li>
                <li style={{color: "green"}}>
                  <i className="fas fa-check text-success"></i> Walking Exploration
                </li>
                <li style={{color: "green"}}>
                  <i className="fas fa-check text-success"></i> Advanced Exploration Tools
                </li>
                <li style={{color: "green"}}>
                  <i className="fas fa-check text-success"></i> Load Scenarios
                </li>
                <li style={{color: "green"}}>
                  <i className="fas fa-check text-success"></i> Randomized Training
                </li>
                <li style={{color: "red"}}>
                  <i className="fas fa-times text-danger"></i> Create/Share Your Own Scenarios
                </li>
                <li style={{color: "red"}}>
                  <i className="fas fa-times text-danger"></i> Networked Exploration and Training
                </li>
              </ul>
            </div>
            <div className="card-action center">
              <input
                placeholder="Referral Code"
                style={{ width: "200px", margin: "0px" }}
                type="text"
                name="name"
                onChange={onReferralCodeChangeHandler}
                value={referralCode}
              />

              <StripeCheckout
                image="https://www.enomalies.net/enom-logo-center.png"
                stripeKey="pk_live_TBYf1ZxD4rUfFD9WOIFVlJP7007VlV7M1k"
                //stripeKey="pk_test_GFJ4kw9ZBG1QhwYGPNli9DWi00TAnpgCLu"
                token={handleGoPaymentSubmit}
                name={RSET_GO.name}
                amount={RSET_GO.price * 100}
                billingAddress
              >
                <button className="btn btn-primary my-1">
                  Buy RSET Go (${RSET_GO.price})
                </button>
              </StripeCheckout>
            </div>
          </div>
        </div>
        <div className="col m4">
          <div className="card medium">
            <div className="card-content">
              <span className="card-title">
                RSET Pro
              </span>
              <ul>
              <li style={{color: "green"}}>
                  <i className="fas fa-check text-success"></i> Explore unlimited environments
                </li>
                <li style={{color: "green"}}>
                  <i className="fas fa-check text-success"></i> Walking Exploration
                </li>
                <li style={{color: "green"}}>
                  <i className="fas fa-check text-success"></i> Advanced Exploration Tools
                </li>
                <li style={{color: "green"}}>
                  <i className="fas fa-check text-success"></i> Load Scenarios
                </li>
                <li style={{color: "green"}}>
                  <i className="fas fa-check text-success"></i> Randomized Training
                </li>
                <li style={{color: "green"}}>
                  <i className="fas fa-check text-success"></i> Create/Share Your Own Scenarios
                </li>
                <li style={{color: "green"}}>
                  <i className="fas fa-check text-success"></i> Networked Exploration and Training
                </li>
              </ul>
            </div>
            <div className="card-action center">
              <form action="mailto:schmidt@rset.com,info@rset.com?subject=RSET%20Pro%20Info%20Request">
                <input type="submit" value="Contact Us for Pricing" className="btn btn-primary my-1"/>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Link className="btn btn-light my-1" to="/dashboard">
        Go Back
      </Link>
    </div>
  );
};

PurchaseTier.propTypes = {
  buyRSET_Lite: PropTypes.func.isRequired,
  buyRSET_Go: PropTypes.func.isRequired,
  buyRSET_Pro: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  // addRegCode: PropTypes.func.isRequired,
  // showRegAlert: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  buyRSET_Lite,
  buyRSET_Go,
  buyRSET_Pro,
})(PurchaseTier);
