const getRoleName = (roleNumber) => {
  if (roleNumber === "role1") {
    return "defense";
  } else if (roleNumber === "role2") {
    return "entertainment";
  } else if (roleNumber === "role3") {
    return "education";
  } else if (roleNumber === "role4") {
    return "construction";
  } else if (roleNumber === "role5") {
    return "gaming";
  } else {
    return "entertainment";
  }
};

const getRoleNumber = (roleName) => {
  if (roleName === "defense") {
    return "role1";
  } else if (roleName === "entertainment") {
    return "role2";
  } else if (roleName === "education") {
    return "role3";
  } else if (roleName === "construction") {
    return "role4";
  } else if (roleName === "gaming") {
    return "role5";
  } else {
    return "role2";
  }
};

export { getRoleName, getRoleNumber };
