import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { Link, withRouter } from "react-router-dom";
import { editUser, showRegAlert } from "../../actions/user";
import { getRoleName, getRoleNumber } from "../../utils/userHelper";

const initialState = {
  expdate: moment().add(30, "days").format("YYYY-MM-DD"),
  email: "",
  role: "",
  tier: "",
};

const EditUser = ({ auth: { user }, editUser, showRegAlert, ...props }) => {
  // Destruct props (getting the current regcode related data)
  const {
    expdate: curExpdate,
    email: targetUserEmail,
    tier: curTier,
    role: curRole,
  } = props.location.state;

  const currentExpirationDate = new Date(curExpdate);
  const currentExpirationDateString = currentExpirationDate
    .toISOString()
    .substr(0, 10);

  initialState.expdate = currentExpirationDateString;
  initialState.email = targetUserEmail;
  initialState.tier = curTier;
  initialState.role = getRoleNumber(curRole.toLowerCase());

  const [formData, setFormData] = useState(initialState);

  let { expdate, email, role, tier } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <h1 className="large text-primary">Edit User</h1>
      <p className="lead">
        <i className="fas fa-id-badge"></i> Edit Existing User
      </p>
      {/* <small>* = required field</small> */}
      <div className="row">
        <form
          className="col s12"
          onSubmit={(e) => {
            e.preventDefault();
            // Check if expdate is valid
            if (isNaN(Date.parse(formData.expdate)) === false) {
              // Adjust timezone to EST for now
              const adjustFormData = {
                ...formData,
                role: getRoleName(role),
                expdate: new Date(formData.expdate).toISOString(),
              };
              editUser(adjustFormData);
            } else {
              showRegAlert("Invalid date", "danger");
            }
          }}
        >
          <div className="row col s12">
            <label>User Email</label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => onChange(e)}
              required
              disabled={true}
            ></input>
          </div>
          <div className="row col s12">
            <label>Tier(*)</label>
            <select
              className="browser-default"
              name="tier"
              value={tier}
              onChange={(e) => onChange(e)}
            >
              <option disabled>* Select tier to be assigned</option>
              <option value="tier1">
                Tier 1: single/bundle scan purchases, no subscription
              </option>
              <option value="tier2">
                Tier 2: unlimited scans, subscription, basic features
              </option>
              <option value="tier3">
                Tier 3: unlimited scans, subscription, all features
              </option>
              <option value="tier4">
                Tier 4: power users, special financing, all features incl' beta
                features
              </option>
            </select>
          </div>

          <div className="row col s12">
            <label>Role(*)</label>
            <select
              className="browser-default"
              name="role"
              value={role}
              onChange={(e) => onChange(e)}
            >
              <option disabled>* Select role to be assigned</option>
              <option value="role1">Role 1: Defense</option>
              <option value="role2">Role 2: Entertainment</option>
              <option value="role3">Role 3: Education</option>
              <option value="role4">Role 4: Construction</option>
              <option value="role5">Role 5: Gaming</option>
            </select>
          </div>
          <div className="row col s12">
            <label>Expiration Date</label>
            <input
              type="date"
              name="expdate"
              value={expdate}
              onChange={(e) => onChange(e)}
              required
            ></input>
            <small className="form-text-warning">Expiration date is EST</small>
          </div>
          <input
            type="submit"
            value="Update"
            className="btn btn-primary my-1"
          />
          <Link className="btn btn-light my-1" to="/view-profiles">
            Go Back
          </Link>
        </form>
      </div>
    </div>
  );
};

EditUser.propTypes = {
  auth: PropTypes.object.isRequired,
  editUser: PropTypes.func.isRequired,
  showRegAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { editUser, showRegAlert })(
  withRouter(EditUser)
);
